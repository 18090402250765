import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Gallery from "./Gallery"

const Photos = () => {
  const { lodge } = useStaticQuery(graphql`
    query {
      lodge: contentfulGallery(name: { eq: "Lodge" }) {
        images {
          contentful_id
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return <Gallery data={lodge} subHeader="THE LODGE" id="lodge"></Gallery>
}

export default Photos
