import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Gallery from "../components/Gallery"
import { PageLayout } from "../components/Layout"
import { SectionHeader } from "../components/Typography"
import TextSectionBackground from "../components/TextSectionBackground"
import LodgeGallery from "../components/lodgeGallery"
import TentsGallery from "../components/TentsGallery"
import WildlifeGallery from "../components/WildlifeGallery"
import BirdsGallery from "../components/birdsGallery"
import SEO from "../components/SEO"

const Photos = () => {
  // const { tents, lodge, birds, wildlife, meals } = useStaticQuery(graphql`
  //   query {
  //     tents: contentfulGallery(name: { eq: "Tents" }) {
  //       images {
  //         fluid(maxWidth: 600, quality: 100) {
  //           ...GatsbyContentfulFluid_withWebp_noBase64
  //         }
  //       }
  //     }
  //     lodge: contentfulGallery(name: { eq: "Lodge" }) {
  //       images {
  //         fluid(maxWidth: 600, quality: 100) {
  //           ...GatsbyContentfulFluid_withWebp_noBase64
  //         }
  //       }
  //     }
  //     wildlife: contentfulGallery(name: { eq: "Wildlife" }) {
  //       images {
  //         fluid(maxWidth: 600, quality: 100) {
  //           ...GatsbyContentfulFluid_withWebp_noBase64
  //         }
  //       }
  //     }
  //     birds: contentfulGallery(name: { eq: "Birds" }) {
  //       images {
  //         fluid(maxWidth: 600, quality: 100) {
  //           ...GatsbyContentfulFluid_withWebp_noBase64
  //         }
  //       }
  //     }
  //     meals: contentfulGallery(name: { eq: "Meals" }) {
  //       images {
  //         fluid(maxWidth: 1000, quality: 100) {
  //           ...GatsbyContentfulFluid_withWebp_noBase64
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <PageLayout>
      <SEO 
      title="Gallery" 
      description="A sneak peek into life at Mavela Game Lodge. Enjoy our pictures ranginging from safari-style teneted accomodation to wildlife."
      />
      <TextSectionBackground pa="4% 5%">
        <SectionHeader>Gallery</SectionHeader>
        <TentsGallery></TentsGallery>
        <LodgeGallery></LodgeGallery>
        <WildlifeGallery></WildlifeGallery>
        <BirdsGallery></BirdsGallery>
        {/* <Gallery
          data={tents}
          subHeader="SAFARI-STYLE TENTED ACCOMMODATION"
          id="tents"
        ></Gallery>
        <Gallery data={lodge} subHeader="THE LODGE" id="lodge"></Gallery>
        <Gallery data={wildlife} subHeader="WILDLIFE" id="big5"></Gallery>
        <Gallery data={birds} subHeader="BIRDS" id="birds"></Gallery>
        <Gallery data={meals} subHeader="DELICIOUS FOOD" id="meals"></Gallery> */}
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Photos
